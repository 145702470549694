
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Select,
  SimpleGrid,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useTheme,
  FormControl,
  FormErrorMessage,
  Input,
  IconButton,
  useToast
} from '@chakra-ui/react';


import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import TagsField from 'components/fields/TagsField';
import Dropzone from 'views/admin/main/ecommerce/newProduct/components/Dropzone';
import React, { useEffect, useRef, useState } from 'react';
import Hello from './components/TablaDinamica.js';
import { useJwt } from "react-jwt";

// Assets
import { MdAdd, MdOutlineCloudUpload, MdPhoto, MdUploadFile } from 'react-icons/md';
import { useForm } from 'react-hook-form'
import { getCookie } from 'cookies-next';

export default function NewProduct() {
  const inputFileRef = useRef(null);
  const token = getCookie('token')

  const jwt = useJwt(token);

  const inputFileRefevi = useRef(null);
  const inputFileRefevi2 = useRef(null);
  const inputFileRefevi3 = useRef(null);
  const toast = useToast()

  // const inputFileRefevi = useRef(null);

  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  }

  const onBtnClickevi = () => {
    /*Collecting node-element and performing click*/
    inputFileRefevi.current.click();
  }

  const onBtnClickevi2 = () => {
    /*Collecting node-element and performing click*/
    inputFileRefevi2.current.click();
  }

  const onBtnClickevi3 = () => {
    /*Collecting node-element and performing click*/
    inputFileRefevi3.current.click();
  }
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({
    product: true,
    media: false,
    pricing: false,
  });
  const [file, setFile] = useState()
  const [file1, setFile1] = useState()
  const [file2, setFile2] = useState()
  const [data, setData] = useState([]);
  const [candi, setCandi] = useState('') //SELECCION DE CANDIDATO

  const [textFile, setTextFile] = useState('')
  const [textFileEvidencia, settextFileEvidencia] = useState('')
  const [textFileEvidencia2, settextFileEvidencia2] = useState('')
  const [cuestionario, setCuestionario] = useState([]);

  const [Empresa, setEmpresa] = useState('')
  const [Categoria, setCategoria] = useState('')
  const [NC, setNC] = useState('')

  const Clientes = [
    { id: 1, name: 'Alice', country: 'Austria' },
    { id: 2, name: 'Bob', country: 'Belgium' },
    { id: 3, name: 'Carl', country: 'Canada' },
    { id: 4, name: 'Dean', country: 'Denmark' },
    { id: 5, name: 'Ethan', country: 'Egypt' },
  ];



  function handleChange(event: any) {
    setFile(event.target.files[0]);
    try {
      if (event.target?.files[0])

        setTextFile(String(event.target.files[0].name));
    } catch {
      setTextFile('');
      console.log('Sin nombre')
    }
    console.log(event.target.files[0]);
  }

  function handleChangeEvi(event: any) {
    setFile1(event.target.files[0]);
    try {
      if (event.target?.files[0])

        settextFileEvidencia(String(event.target.files[0].name));
    } catch {
      settextFileEvidencia('');
      console.log('Sin nombre')
    }
    console.log(event.target.files[0]);
  }

  function handleChangeEvi2(event: any) {
    setFile2(event.target.files[0]);
    try {
      if (event.target?.files[0])

        settextFileEvidencia2(String(event.target.files[0].name));
    } catch {
      settextFileEvidencia2('');
      console.log('Sin nombre')
    }
    console.log(event.target.files[0]);
  }


  function handleChangeCliente(event: any) {
    alert(event.target.value)
    //setEmpresa(event.target.value)
  }

  function handleChangeEmpresa(event: any) {
    //alert(event.target.value)
    setEmpresa(event.target.value)
  }

  function handleChangeCategoria(event: any) {
    //alert(event.target.value)

    setCategoria(event.target.value)
  }


  const [message, setMessage] = useState('');


  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()

  function onSubmit(values: any) {
    return new Promise(async (resolve) => {
      const formData = new FormData();
      //var json = JSON(values, null, 2);
      for (var key in values) {
        console.warn(key)
        let value = values[key];
        formData.append(key, value);
      }

    
      let valores =  JSON.stringify(jwt.decodedToken);
      let valoresx = await JSON.parse(valores);
    //console.log(valores)
   //  setNC(valoresx.id);
      
      
      formData.append('Evidencia', file1);
      formData.append('id', file1);
      formData.append('who',valoresx.usuarioid);

    //  return 0;
      formData.append('Evento', Categoria);
      
      console.log(formData);

      let preguntasx = JSON.stringify(cuestionario);
      let x = JSON.parse(preguntasx)
      formData.append('Lineas', JSON.stringify(x));

      formData.forEach((x)=>{
        console.log(x)
      })


			const registrationToast = toast({
				title: "Guardando Venta",
				status: "loading",
			});


      const response = await fetch(process.env.REACT_APP_URL_SERVER + '/new_venta',
        {
          body: formData,
          method: "post"
        });


        toast.close(registrationToast);
        toast({
          title: "Venta Guardada",
          status: "success",
        });
    })
  }

  let valores = JSON.stringify(jwt.decodedToken);
  let valoresx = JSON.parse(valores);

  const productTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const mediaTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const pricingTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const theme = useTheme();
  //eslint-disable-next-line
  const [lineColor, setLineColor] = useState(theme.colors.brand[500]);
  //eslint-disable-next-line
  const [lineColorDark, setLineColorDark] = useState(theme.colors.brand[400]);
  const brand = useColorModeValue(lineColor, lineColorDark);
  //const {register, handleSubmit, formState: {errors}} = useForm();
  const url = process.env.REACT_APP_URL_SERVER + '/proveedores';


  const [data1, setData1] = useState([]);

  const [genero,setgenero] = useState([]);
  // let genero = [
  //   { id: "1", valor: "Femenino" },
  //   { id: "2", valor: "Masculino" },
  //   { id: "3", valor: "Sin Definir" }
  // ]


  const url2 = process.env.REACT_APP_URL_SERVER + '/eventos';
  const [data2, setData2] = useState([]);

  useEffect(() => {
    //
 

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      //let url2 = process.env.REACT_APP_URL_SERVER + '/cuentasxpagar';

    
      // const response = await fetch(url);
      // const result = await response.json();
      // setData(result);

      const responsex = await fetch(url2);
      const resultx = await responsex.json();
      setgenero(resultx);
      //const xD: RowObj[] = result;
      //setData(xD);
      //  setisLoading(false)

     // valores =  JSON.stringify(jwt.decodedToken);
    //  valoresx = await JSON.parse(valores);
    // console.log(valores)
    //  setNC(valoresx.id);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: '125px', lg: '75px' }}
        position="relative"
      >
        {/* 
        <Box
          h="45vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        /> */}

        <Tabs
        // variant="unstyled"
        // mt={{ base: '60px', md: '165px' }}
        // zIndex="0"
        // display="flex"
        // flexDirection="column"
        >
          <TabPanels mt="24px" maxW={{ md: '100%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '1050px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Flex direction="column" w="100%">
                  <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                    <Stack direction="column" gap="20px">
                      {/* <Text style={{fontWeight:'bold',marginBottom:0}}>
                        Cliente
                      </Text> */}
                        <InputField
                        mb="0px"
                        id="Titular"
                        name="Titular"
                        placeholder="Enrique Guzman Roldan"
                        label="A Nombre de"
                        nombre="Titular"
                        registro={register}
                      />

                      <Flex direction="column" mb="34px">
                        <FormLabel
                          ms="10px"
                          htmlFor="Categoria"
                          fontSize="sm"
                          color={textColor}
                          fontWeight="bold"
                          _hover={{ cursor: 'pointer' }}
                        >
                          Carga de Evidencia (Fotografia)
                        </FormLabel>
                        <Input
                          style={{ display: "none" }}
                          ref={inputFileRefevi3}
                          accept=".pdf,.jpg,.png,.jpeg"
                          id='filexez'
                          type="file" onChange={handleChangeEvi} />
                        <IconButton onClick={onBtnClickevi3} aria-label={''}>
                          <Icon as={MdPhoto} h='34px' w='34px' color='red.300' />
                        </IconButton>
                        <Text> Archivo Seleccionado: {textFileEvidencia}</Text>
                      </Flex>

                    
                    </Stack>
                    <Stack direction="column" gap="20px">



                      <InputField
                        mb="0px"
                        id="Fecha"
                        name="Fecha"
                        type='datetime-local'
                        placeholder="12/12/2024"
                        label="Fecha y hora del Evento"
                        nombre="Fecha"
                        registro={register}
                      />

                      <Flex direction="column" mb="34px">
                        <FormLabel
                          ms="10px"
                          htmlFor="Evento"
                          fontSize="sm"
                          color={textColor}
                          fontWeight="bold"
                          _hover={{ cursor: 'pointer' }}

                        >
                          Evento
                        </FormLabel>
                        

                        <Select fontSize="sm"
                          id="Evento"
                          variant="main"
                          h="44px"
                          maxH="44px"
                          name="Evento"
                          onChange={handleChangeCategoria}
                        >
                          <option> </option>
                          {genero.map((e, key) => {
                            return <option key={key} value={e.Nombre}>{e.Nombre}</option>;
                          })}
                        </Select>
                      </Flex>

                      <InputField
                        mb="0px"
                        id="Avistamientos"
                        name="Avistamientos"
                        placeholder="..."
                        label="Avistamientos"
                        nombre="Avistamientos"
                        registro={register}
                      />

                    </Stack>
                    <Stack direction="column" gap="20px">
                      <Flex>
                        <Hello info={setCuestionario} />

                      </Flex>
                    </Stack>
                  </SimpleGrid>




                  <Flex justify="center" mt="24px">
                    <Button
                      variant="darkBrand"
                      type='submit'
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                    // onClick={handleClick}
                    >
                      Guardar
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </form>
  );
}
