import React, { useState, useRef } from 'react';

// Chakra imports
import { Box, Button, Flex, Link, Text, Image } from '@chakra-ui/react';
import Calendar from "react-calendar";


// Assets
import banner from 'assets/img/product/OverviewBanner.png';
import MiniCalendar from 'components/calendar/CalendarioOBS';
import bgMastercard from 'assets/img/profile/TTW.png';

export default function Banner() {
	const refComponenteExterno = useRef(null);
	const inputRef = useRef(null);
	const valor = new Date();
	const [valorRecuperado, setValorRecuperado] = useState();
	const manejarRecuperacion = (valor: any) => {
		setValorRecuperado(valor);
	};
	const handleClick = () => {
		console.log(inputRef.current.valor);
		//	alert("Valor recuperado: " + inputRef.current.value);
		//alert("Valor recuperado: " + inputRef);
	};

	// const obtenerValorComponente = () => {
	// 	if (refComponenteExterno.current) {
	// 	  const valor = refComponenteExterno.current.valor;
	// 	  console.log(valor);
	// 	}
	//   };

	type ValuePiece = Date | null;
	type Value = ValuePiece | [ValuePiece, ValuePiece];

	const [value, onChange] = useState<Value>(new Date());
	const getCountValue = (yourvalue: any) => {
		console.log(yourvalue);
	};
	// Chakra Color Mode
	return (
		<Flex
			direction='column'
			bgImage={banner}
			bgSize='cover'
			mb='20px'
			py={{ base: '30px', md: '56px' }}
			px={{ base: '30px', md: '64px' }}
			borderRadius='30px'>
			<Text
				fontSize={{ base: '24px', md: '34px' }}
				color='white'
				mb='14px'
				maxW={{
					base: '75%',
					md: '54%',
					lg: '65%',
					xl: '65%',
					'2xl': '50%',
					'3xl': '42%'
				}}
				fontWeight='700'
				lineHeight={{ base: '32px', md: '42px' }}>
				Saca el mejor partido a tu Sistema Personalizado!
			</Text>

				<Image src={bgMastercard} boxSize='100px'
					objectFit='cover' alt='Dan Abramov' />
			{/* <Text
				fontSize='md'
				color='#E3DAFF'
				maxW={{
					base: '90%',
					md: '64%',
					lg: '66%',
					xl: '56%',
					'2xl': '46%',
					'3xl': '38%'
				}}
				fontWeight='500'
				mb='40px'
				lineHeight='28px'>
				Saca el mejor partido a tu Sistema Personalizado! 
			</Text> */}
		</Flex>

	);
}
