
import { Box, Grid, useColorModeValue } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import OrderStatus from './components/OrderStatus';
import Receipt from './components/Receipt';
import Details from './components/Details';
import { useParams } from 'react-router-dom';

export default function Invoice(props: any) {
  const textColor = useColorModeValue('gray.700', 'white');
  const bgButton = 'rgba(255,255,255,0.12)';
  const bgHover = { bg: 'whiteAlpha.50' };
  const bgFocus = { bg: 'rgba(255,255,255,0.12)' };
  // const { id } = useParams();
  const { id } = useParams();

  console.error(id);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({

    content: () => componentRef.current,
    pageStyle: "@page { size: 2.5in 4in, margin:0 }",
    removeAfterPrint: true,

  });

  //console.error(id);
  return (
    <Box>
        <Receipt
        id={props.id}
          // me="5px"
          // gridArea={{ base: '1 / 1 / 2 / 3', lg: '1 / 1 / 2 / 2' }}
          ref={componentRef}
          handlePrint={handlePrint}
          textColor={textColor}
          bgButton={bgButton}
          bgHover={bgHover}
          bgFocus={bgFocus}
        />
    </Box>
  );
}
