import { Icon } from '@chakra-ui/react';
import {
  MdDashboard,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from 'react-icons/md';

// Admin Imports
// import DashboardsDefault from 'views/admin/dashboards/default';
//import DashboardsRTLDefault from 'views/admin/dashboards/rtl';
// import Secciones from 'views/admin/dashboards/secciones';
// import DashboardsSmartHome from 'views/admin/dashboards/smartHome';

// // NFT Imports
// import NFTMarketplace from 'views/admin/nfts/marketplace';
// import NFTPage from 'views/admin/nfts/page';
// import NFTCollection from 'views/admin/nfts/collection';
// import NFTProfile from 'views/admin/nfts/profile';

// Main Imports
// import AccountBilling from 'views/admin/main/account/billing';
// import AccountApplications from 'views/admin/main/account/application';
// import AccountInvoice from 'views/admin/main/account/invoice';
// import AccountSettings from 'views/admin/main/account/settings';
// import AccountAllCourses from 'views/admin/main/account/courses';
// import AccountCoursePage from 'views/admin/main/account/coursePage';

// import UserNew from 'views/admin/main/users/newUser';
// import UsersOverview from 'views/admin/main/users/overview';
// import UsersReports from 'views/admin/main/users/reports';

// import ProfileSettings from 'views/admin/main/profile/settings';
// import ProfileOverview from 'views/admin/main/profile/overview';
// import ProfileNewsfeed from 'views/admin/main/profile/newsfeed';

// import ApplicationsKanban from 'views/admin/main/applications/kanban';
// import ApplicationsDataTables from 'views/admin/main/applications/dataTables';
// import ApplicationsCalendar from 'views/admin/main/applications/calendar';

// import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
// import EcommerceProductOverview from 'views/admin/main/ecommerce/overviewProduct';
// import EcommerceProductSettings from 'views/admin/main/ecommerce/settingsProduct';
// import EcommerceProductPage from 'views/admin/main/ecommerce/pageProduct';
// import EcommerceOrderList from 'views/admin/main/ecommerce/orderList';
// import ProductosLista from 'views/admin/main/ecommerce/productoslista';
// import EcommerceOrderDetails from 'views/admin/main/ecommerce/orderDetails';
// import EcommerceReferrals from 'views/admin/main/ecommerce/referrals';

// Others
// import OthersNotifications from 'views/admin/main/others/notifications';
// import OthersPricing from 'views/admin/main/others/pricing';
// import OthersError from 'views/admin/main/others/404';
// import Messages from 'views/admin/main/others/messages';

// Auth Imports
// import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered';
// import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault';
// import LockCentered from 'views/auth/lock/LockCentered';
// import LockDefault from 'views/auth/lock/LockDefault';
// import SignInCentered from 'views/auth/signIn/SignInCentered';
import SignInDefault from 'views/auth/signIn/SignInDefault';
// import SignUpCentered from 'views/auth/signUp/SignUpCentered';
// import SignUpDefault from 'views/auth/signUp/SignUpDefault';
// import VerificationCentered from 'views/auth/verification/VerificationCentered';
// import VerificationDefault from 'views/auth/verification/VerificationDefault';
/////// OBS

// import ListaLocales from 'views/admin/main/ecommerce/Locales';
// import ListaUsuarios from 'views/admin/main/ecommerce/Usuarios';

// import NuevoLocal from 'views/admin/main/ecommerce/NuevoLocal';
// import NuevoUsuario from 'views/admin/main/ecommerce/NuevoUsuario';


// VISITANTES

// import NuevoVotante from 'views/admin/main/ERP/Votantes/NuevoVotante';
// import RegistrosVotantes from 'views/admin/main/ERP/Votantes/RegistrosVotantes';

//  CANDIDATO 
// import NuevoCandidato from 'views/admin/main/ERP/Candidatos/NuevoCandidato';
// import RegistroCandidato from 'views/admin/main/ERP/Candidatos/RegistroCandidato';

//  CANDIDATO 
// import NuevaEncuesta from 'views/admin/main/ERP/Candidatos/NuevaEncuesta';
// import RegistroEncuesta from 'views/admin/main/ERP/Candidatos/RegistroEncuesta';

// import Encuestas from 'views/admin/main/ERP/Candidatos/Encuestas';

//  Administradores 
import NuevoAdmin from 'views/admin/main/ERP/Usuarios/NuevoAdmin';
import RegistroAdmin from 'views/admin/main/ERP/Usuarios/RegistroAdmin';


//personal
// import NuevoPersonal from 'views/admin/main/ERP/Personal/NuevoPersonal';
// import RegistroPersonal from 'views/admin/main/ERP/Personal/RegistroPersonal';




// CLIENTES

// import NuevoCliente from 'views/admin/main/ERP/Clientes/NuevoCliente';
// import RegistrosCliente from 'views/admin/main/ERP/Clientes/RegistrosCliente';



// PROVEEDOR

// import NuevoProveedor from 'views/admin/main/ERP/Proveedores/NuevoProveedor';
// import RegistroProveedor from 'views/admin/main/ERP/Proveedores/RegistroProveedores';

// COMPRAS

// import NuevaCompra from 'views/admin/main/ERP/Compras/NuevaCompra';
// import RegistrosCompras from 'views/admin/main/ERP/Compras/RegistrosCompras';


// VENTAS
import NuevaVenta from 'views/admin/main/ERP/Ventas/NuevaVenta';
import RegistrosVentas from 'views/admin/main/ERP/Ventas/RegistrosVentas';
//INGRESOS 

// import NuevoIngreso from 'views/admin/main/ERP/Ingresos/NuevoIngreso';
// import RegistrosIngresos from 'views/admin/main/ERP/Ingresos/RegistrosIngresos';

// AGENDA DE ACTIVIDADES


// import NuevaActividad from 'views/admin/main/ERP/AgendaActividades/NuevaActividad';
// import RegistroActividades from 'views/admin/main/ERP/AgendaActividades/RegistroActividades';
// import Album from 'views/admin/main/ERP/AgendaActividades/Album';

// Configuracion 

// import AjustePositivo from 'views/admin/main/ERP/Configuracion/AjustePositivo';
// import AjusteNegativo from 'views/admin/main/ERP/Configuracion/AjusteNegativo';
// import CargaInicial from 'views/admin/main/ERP/Configuracion/CargaInicial';
// import Notificaciones from 'views/admin/main/ERP/Configuracion/Notificaciones';

//Supervisores
// import NuevoSuper from 'views/admin/main/ERP/Supervisores/NuevoPersonal';
// import RegistroSuper from 'views/admin/main/ERP/Supervisores/RegistroPersonal';

//import RegistrosIngresos from 'views/admin/main/ERP/Ingresos/RegistrosIngresos';



const routes = [
  {
    name: 'Sistema',
    path: '/main',
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Usuarios',
        path: '/Usuarios',
        collapse: true,
        items: [
          {
            name: 'Nuevo Usuario',
            layout: '/admin',
            path: '/Usuarios/NuevoAdmin',
            exact: false,
            component: <NuevoAdmin />,
          }, {
            name: 'Registro de Usuarios',
            layout: '/admin',
            path: '/Usuarios/RegistroAdmin',
            exact: false,
            component: <RegistroAdmin />,
          },
      
        ]
      },

    
      {
        name: 'Ventas',
        path: '/Ventas',
        collapse: true,
        items: [
          {
            name: 'Nueva Venta',
            layout: '/ventas',
            path: '/Ventas/NuevaVenta',
            exact: false,
            component: <NuevaVenta />,
          }, {
            name: 'Registro de Ventas',
            layout: '/ventas',
            path: '/Ventas/RegistrosVentas',
            exact: false,
            component: <RegistrosVentas />,
          },
        ]
      },

    ]
  }

]

export default routes;