import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import ReceiptTable from '../components/ReceiptTable';

export default function Content(props: { [x: string]: any }) {
	const { ...rest } = props;
	type RowObj = {
		item: string[];
		quantity: number;
		rate: number;
		amount: number;
	};
	var arreglo = props.x;
	var adicional = props.y;
	const productos: RowObj[] =[];

	console.warn(arreglo);
	for (let i = 0; i < arreglo.length; i++) {
		// var totalx = 0;
		//totalx = parseInt(arreglo[i].cantidad)*parseFloat(arreglo[i].Producto.price);
		productos.push({
			'item':[arreglo[i].Articulo,arreglo[i].id,arreglo[i].Descripcion],
			'quantity':parseInt(arreglo[i].Cantidad),
			'rate':parseFloat(arreglo[i].Precio),
			'amount':arreglo[i].Total
		})
	}
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	let paid = 0;
	let total = 0;
	for (let i = 0; i < productos.length; i++) {
		total = total + productos[i].amount;
	}
	return (
		<Flex direction='column' {...rest} >
			<ReceiptTable tableData={productos} />
			{/* <Flex align='right' justifyContent='space-between' mb='12px'>
						<Text textAlign='end' color={textColor} fontSize='small' fontWeight='400'>
							Subtotal
						</Text>
						<Text color={textColor} fontSize='small' fontWeight='700' maxW='292px'>
							${total} 
						</Text>
					</Flex> */}

					<HSeparator my='20px' />
					<Flex align='center' justifyContent='space-between'>
						<Text me='70px' fontWeight='700' textAlign='end' color={textColor} fontSize='xx-large'>
							Total
						</Text>
						<Text color={textColor} fontSize='xx-large' fontWeight='700' maxW='292px'>
							${total + parseFloat(adicional)}
						</Text>
					</Flex> 
			
			
			</Flex>
		
	);
}
