
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Select,
  SimpleGrid,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useTheme,
  FormControl,
  FormErrorMessage,
  Input,
  IconButton,
} from '@chakra-ui/react';


import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import TagsField from 'components/fields/TagsField';
import Dropzone from 'views/admin/main/ecommerce/newProduct/components/Dropzone';
import React, { useEffect, useRef, useState } from 'react';
import Hello from './components/TablaDinamica.js';

// Assets
import { MdAdd, MdOutlineCloudUpload, MdPhoto, MdUploadFile } from 'react-icons/md';
import { useForm } from 'react-hook-form'

export default function NewProduct() {
  const inputFileRef = useRef(null);

  const inputFileRefevi = useRef(null);
  const inputFileRefevi2 = useRef(null);
  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  }

  const onBtnClickevi2 = () => {
    /*Collecting node-element and performing click*/
    inputFileRefevi2.current.click();
  }
  const onBtnClickevi = () => {
    /*Collecting node-element and performing click*/
    inputFileRefevi.current.click();
  }
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({
    product: true,
    media: false,
    pricing: false,
  });
  const [file, setFile] = useState()
  const [file2, setFile2] = useState()
  const [textFile, setTextFile] = useState('')
  const [textFileEvidencia, settextFileEvidencia] = useState('')
  const [textFileEvidencia2, settextFileEvidencia2] = useState('')
  const [Empresa, setEmpresa] = useState('')
  const [Categoria, setCategoria] = useState('')
  const Clientes = [
    { id: 1, name: 'Alice', country: 'Austria' },
    { id: 2, name: 'Bob', country: 'Belgium' },
    { id: 3, name: 'Carl', country: 'Canada' },
    { id: 4, name: 'Dean', country: 'Denmark' },
    { id: 5, name: 'Ethan', country: 'Egypt' },
  ];



  function handleChange(event: any) {
    setFile(event.target.files[0]);
    try {
      if (event.target?.files[0])

        setTextFile(String(event.target.files[0].name));
    } catch {
      setTextFile('');
      console.log('Sin nombre')
    }
    console.log(event.target.files[0]);
  }

  function handleChangeEvi2(event: any) {
    setFile2(event.target.files[0]);
    try {
      if (event.target?.files[0])

        settextFileEvidencia2(String(event.target.files[0].name));
    } catch {
      settextFileEvidencia2('');
      console.log('Sin nombre')
    }
    console.log(event.target.files[0]);
  }


  function handleChangeEvi(event: any) {
    setFile(event.target.files[0]);
    try {
      if (event.target?.files[0])

        settextFileEvidencia(String(event.target.files[0].name));
    } catch {
      settextFileEvidencia('');
      console.log('Sin nombre')
    }
    console.log(event.target.files[0]);
  }

  function handleChangeCliente(event: any) {
    alert(event.target.value)
    //setEmpresa(event.target.value)
  }

  function handleChangeEmpresa(event: any) {
    //alert(event.target.value)
    setEmpresa(event.target.value)
  }

  function handleChangeCategoria(event: any) {
    //alert(event.target.value)

    setCategoria(event.target.value)
  }


  const [message, setMessage] = useState('');


  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()

  function onSubmit(values: any) {
    return new Promise(async (resolve) => {
      const formData = new FormData();
      //var json = JSON(values, null, 2);
      for (var key in values) {
        console.warn(key)
        let value = values[key];
        formData.append(key, value);
      }
      // formData.append('Fotografia', file);
      // formData.append('LogoPartido', file2);
      //  formData.append('Partido', Empresa);
      // formData.append('Categoria', Categoria);
      // formData.append('fileName', 'archivito');
      console.log(formData);
      const response = await fetch(process.env.REACT_APP_URL_SERVER+'/new_admin',
        {
          body: formData,
          method: "post"
        });
      alert('Agregado')

      // setTimeout(() => {
      //   alert(JSON.stringify(values, null, 2))
      //   //resolve()
      // }, 100)
    })
  }

  const productTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const mediaTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const pricingTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const theme = useTheme();
  //eslint-disable-next-line
  const [lineColor, setLineColor] = useState(theme.colors.brand[500]);
  //eslint-disable-next-line
  const [lineColorDark, setLineColorDark] = useState(theme.colors.brand[400]);
  const brand = useColorModeValue(lineColor, lineColorDark);
  //const {register, handleSubmit, formState: {errors}} = useForm();
  const url = process.env.REACT_APP_URL_SERVER+'/empresas';
  const [data1, setData1] = useState([]);
  let genero = [
    { id: "1", valor: "VERDE" },
    { id: "2", valor: "PRD" },
    { id: "3", valor: "PT" },
    { id: "4", valor: "MORENA" }
  ]


  const url2 = process.env.REACT_APP_URL_SERVER+'/categorias';
  const [data2, setData2] = useState([]);

  useEffect(() => {
    //
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      
      // const response = await fetch(url);
      // const result = await response.json();

      // console.warn(result)
      // setData1(result);
      // const response1 = await fetch(url2);
      // const result1 = await response1.json();
      // setData2(result1);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    
    <form onSubmit={handleSubmit(onSubmit)}>

      <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: '125px', lg: '75px' }}
        position="relative"
      >
        {/* 
        <Box
          h="45vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        /> */}

        <Tabs
        // variant="unstyled"
        // mt={{ base: '60px', md: '165px' }}
        // zIndex="0"
        // display="flex"
        // flexDirection="column"
        >
          <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                              <Flex direction="column" w="100%">
                  <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                    <Stack direction="column" gap="20px">
                  

                    </Stack>
                    <Stack direction="column" gap="20px">


                    </Stack>
           


                  </SimpleGrid>


                  <InputField
                        mb="0px"
                        id="NombreCompleto"
                        name="NombreCompleto"
                        placeholder="Emiliano"
                        label="Nombre Completo"
                        nombre="NombreCompleto"
                        registro={register}
                      />



                      <InputField
                        mb="0px"
                        id="CorreoElectronico"
                        name="CorreoElectronico"
                        placeholder="admin@hotmail.com"
                        label="Correo Electronico"
                        nombre="CorreoElectronico"
                        registro={register}
                      />

                      {/* <InputField
                        mb="0px"
                        id="Telefono"
                        name="Telefono"
                        type='number'
                        placeholder="2411099090"
                        label="Telefono"
                        nombre="Telefono"
                        registro={register}
                      /> */}

                      <InputField
                        mb="0px"
                        id="Password"
                        name="Password"
                        placeholder="b4b8+12"
                        label="Contraseña"
                        nombre="Password"
                        registro={register}
                      />


                  <Flex justify="center" mt="24px">
                    <Button
                      variant="darkBrand"
                      type='submit'
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                    // onClick={handleClick}
                    >
                      Guardar
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </form>
  );
}
