
import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import Banner from 'views/admin/main/ecommerce/overviewProduct/components/Banner';

import SearchTableOrders from 'views/admin/main/ERP/Compras/RegistrosCompras/components/SearchTableOrders';
import tableDataOrders from 'views/admin/main/ecommerce/orderList/variable/tableDataOrders';

export default function SearchUser() {
	type RowObj = {
		Anombre: string;
		id: number;
		TipoCuenta:string;
		fechahora: string;
		updatedAt: string;
		Mesa: number;
		meserosId: number;
		Empresaid: number;
		PrecioTotal: string;
		createdAt: string;
		Estatus: boolean;
		//actions: string;
	};


	const tableDataOrders: RowObj[] = [{ "id": 1, "fechahora": "2023-10-21T22:10:59.556Z",	TipoCuenta:'0', "Anombre": "Axc", "updatedAt": "2023-10-21T22:10:59.556Z", "createdAt": "2023-10-21T22:10:59.556Z", "Estatus": false, "Mesa": 0, "PrecioTotal": "6660", "meserosId": 1, "Empresaid": 1 }, { "id": 2, "fechahora": "2023-10-21T22:10:59.556Z", "Anombre": "Axc", "updatedAt": "2023-10-21T22:10:59.556Z", "createdAt": "2023-10-21T22:10:59.556Z", "Estatus": false, "Mesa": 0, "PrecioTotal": "6660", "meserosId": 1, "Empresaid": 1,'TipoCuenta':'0' }]
	//const tableDataOrders: RowObj[] = []
	const url = process.env.REACT_APP_URL_SERVER+'/r_ventas';
	const [data, setData] = useState([]);
	const [isLoading, setisLoading] = useState(true);


	useEffect(() => {
		//
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await fetch(url);
			const result = await response.json();
			const xD: RowObj[] = result;
			setData(xD);
			setisLoading(false)
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
						<Banner />

			<Card px='0px'>

				{
					!isLoading &&

					<SearchTableOrders tableData={data} />
				}

			</Card>
		</Flex>
	);
}
