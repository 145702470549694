import React, { Component } from "react";
import { Select } from '@chakra-ui/react';

//import { Navbar, Table, Form } from "react-bootstrap";
import { MdShare, MdEdit, MdDownload, MdRemove, MdAdd } from 'react-icons/md';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

import {
  Avatar,
  Box,
  Button,
  DarkMode,
  Flex,
  Icon,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

const initialForm = {
  id: 1,
  Articulo: '',
  Precio: 0,
  Cantidad: 0,
  Descripcion: '',
  Total: 0,

}
export default class Hello extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "",
      Total:0,
      formArray: [
        {
          ...initialForm
        }
      ]
    };
    this.addNewForm = this.addNewForm.bind(this);
  }

  addNewForm = () => {
    let newForm = { ...initialForm };
    newForm.id = this.state.formArray[this.state.formArray.length - 1].id + 1;
    this.setState(prevState => {
      return {
        ...prevState,
        formArray: [...prevState.formArray, newForm]
      }
    })
  }

  setTotal = (e) => {
    let { Total } = this.state;
    Total = e;
    this.setState({
      Total
    })

  }

  setFormValue = (e, index) => {
    let { formArray } = this.state;
    formArray[index][e.target.name] = e.target.value;
    this.setState({
      formArray
    })

    this.props.info(formArray);


    if (e.target.name == 'Precio' || e.target.name == 'Cantidad') {
      let mul = formArray[index]['Precio'] * formArray[index]['Cantidad'];
      formArray[index]['Total'] = mul;
      this.setState({
        formArray
      })

      let suma = 0;
      let userFullnames = formArray.map(function (element) {
        suma += element.Total;
      })
      this.setTotal(suma);
      //alert(suma);
     // formArray[index]['Total']
     // alert(mul);ƒ
    }

  }

  setFormValueArticulo = (e, index) => {
    let { formArray } = this.state;
    formArray[index]['Articulo'] = e;
    this.setState({
      formArray
    })

    this.props.info(formArray);

  }

  removeForm = (id) => {
    const formArray = this.state.formArray.filter(form => form.id != id)
    this.setState({ formArray })

    let suma = 0;
    let userFullnames = formArray.map(function (element) {
      suma += element.Total;
    })
    this.setTotal(suma);
  }


  render() {
    const Productos = [
      { id: 1, name: 'Silla de Madera', country: 'Austria' },
      { id: 2, name: 'Sofa', country: 'Belgium' },
      { id: 3, name: 'Mesa', country: 'Canada' },
      { id: 4, name: 'Pancartas', country: 'Denmark' },
      { id: 5, name: 'Lonas', country: 'Egypt' },
    ];
  let genero = [
    { id: "1", valor: "Niño"},
    { id: "2", valor: "Adulto"},
    { id: "3", valor: "Adulto Mayor" },
    { id: "4", valor: "ADELANTO" }
  ]

    return (
      <div>
        <div className="import">
          {/* <h1>Import New Schedule</h1> */}
          <div className="importTable">
            <Table className="table" id="tables" responsive='md' >
              <thead className="thead-dark">
                <tr style={{ alignContent: 'start', alignItems: 'start',alignSelf:'start' }}>
                  <th>Tipo de Entrada</th>
                  <th>Precio</th>
                  <th>Cantidad</th>
                  <th>Descripcion</th>
                  <th>Total</th>

                </tr>
              </thead>
              <tbody>
                {this.state.formArray.map((form, index) => (
                  <tr key={form.id}>
                    <td>
                    
                      <AutoComplete openOnFocus onChange={(e) => this.setFormValueArticulo(e, index)} style={{ marginTop: 0 }} >
                        <AutoCompleteInput variant="filled"
                          type="text"
                          fontWeight='500'
                          _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                          placeholder="Selecciona un Tipo"
                          h='44px'
                          maxH='44px'
                          w='174px'
                          maxW='174px'
                          name="Articulo"

                        />
                        <AutoCompleteList
                          id="Articulo"

                        >
                          {genero.map((cliente) => (
                            <AutoCompleteItem
                              key={`option-${cliente.id}`}
                              label={cliente.valor}
                              value={cliente.valor}
                            >
                              {cliente.name}
                            </AutoCompleteItem>
                          ))}
                        </AutoCompleteList>
                      </AutoComplete>

                      {/* <select name="queueType" 
                    defaultValue={form.queueType} 
                    onChange={(e)=>this.setFormValue(e, index)}>
                      <option>Veteran Affairs</option>
                      <option>Older Australians</option>
                      <option>Disability Sickness Carers</option>
                      <option>Report Employment Income</option>
                    </select> */}
                    </td>
                    <td>
                      <Input
                        fontWeight='500'
                        variant='filled'
                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                        h='44px'
                        maxH='44px'
                        w='194px'
                        maxW='194px'
                        className="form-control"
                        type="number"
                        step=".01"
                        placeholder="Precio: 17.8"
                        defaultValue={form.Precio}
                        onChange={(e) => this.setFormValue(e, index)}
                        name="Precio"
                        border= '2px solid black'

                        id="Precio"
                      />
                    </td>
                    <td>
                      <Input
                        fontWeight='500'
                        variant='filled'
                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                        h='44px'
                        maxH='44px'
                        w='194px'
                        maxW='194px'
                        className="form-control"
                        type="number"
                        defaultValue={form.Cantidad}
                        placeholder="Cantidad: 9"
                        border= '2px solid black'
                        onChange={(e) => this.setFormValue(e, index)}
                        name="Cantidad"
                        id="Cantidad"
                      />
                    </td>
                    <td>
                      <Input
                        type="text"
                        fontWeight='500'
                        variant='filled'
                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                        h='44px'
                        maxH='44px'
                        w='194px'
                        maxW='194px' className="form-control"
                        defaultValue={form.Descripcion}
                         placeholder="Carlos Mejia"
                         border= '2px solid black'

                        onChange={(e) => this.setFormValue(e, index)}
                        name="Descripcion"
                        id="Descripcion"
                      />
                    </td>
                    <td>
                      <Input

                        fontWeight='500'
                        variant='filled'
                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                        h='44px'
                        maxH='44px'
                        w='194px'
                        maxW='194px'
                        className="form-control"
                        type="number"
                        //  onChange={(e) => this.setFormValue(e, index)}
                        name="Total"
                        id="Total"
                        border= '2px solid black'

                        disabled
                        value={this.state.formArray[index]['Total']}
                      // defaultValue={form.Total}
                      ></Input>

                    </td>
                    {/* <td>
                    <input
                      className="form-control"
                      type="date"
                      name="startDate"
                      defaultValue={form.startDate} 
                      onChange={(e)=>this.setFormValue(e,index)}
                      id="example-date-input"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="date"
                      name="endDate"
                      defaultValue={form.endDate} 
                      onChange={(e)=>this.setFormValue(e,index)}
                      id="example-date-input"
                    />
                  </td>
                  <td>
                  <input
                      className="form-control"
                      type="number"
                      defaultValue={form.capacity} 
                      onChange={(e)=>this.setFormValue(e,index)}
                      name="capacity"
                      id="example-number-input"
                    />
                  </td> */}
                    {/* 
                  <td>
                    <input
                      className="form-control"
                      type="time"
                      name="startTime"
                      defaultValue={form.startTime} 
                      onChange={(e)=>this.setFormValue(e,index)}
                      id="example-time-input"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="time"
                      name="endTime"
                      defaultValue={form.endTime} 
                      onChange={(e)=>this.setFormValue(e,index)}
                      id="example-time-input"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="number"
                      defaultValue={form.capacity} 
                      onChange={(e)=>this.setFormValue(e,index)}
                      name="capacity"
                      id="example-number-input"
                    />
                  </td> */}
                    <td>
                      <IconButton aria-label="1" me='2px' px='0px' variant='no-hover' bg='transparent' onClick={() => this.removeForm(form.id)}>
                        <Icon as={MdRemove} h='24px' w='24px' color='red.300' />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <IconButton onClick={this.addNewForm}>
              <Icon as={MdAdd} h='24px' w='24px' color='green.300' />
            </IconButton>
            {/* <button id="addRow" type="button" onClick={this.addNewForm}>+ Add</button> */}
          </div>
        </div>
        <center>
        <Flex style={{alignSelf:'center',alignItems:'center'}}>
        <Text style={{fontWeight:'bold',alignSelf:'center'}}>TOTAL: {this.state.Total}</Text>
        </Flex>
        </center>
        {/* <pre>
        {JSON.stringify(this.state.formArray)}
        </pre> */}
      </div>
    );
  }
}
