/* eslint-disable */

import { Badge, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Icon, Stack, useDisclosure, FormLabel, Input } from '@chakra-ui/react';
// Custom components   
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import * as React from 'react';
// Assets  
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import Invoice from "./orderDetails";
import { useForm } from 'react-hook-form';

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table'

type RowObj = {

  id: number;
  UsoAcceso:boolean;
  Acceso:string;
  Evento:string;
  Titular:string;
  Total: number;
  createdAt: string;

};

export default function SearchTableOrders(props: { tableData: any }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  //const { Order,setOrder } = .useState('')
  const [orden, setOrden] = React.useState(0);
  const [Razon, setRazon] = React.useState('');

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');


  const { tableData } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  let defaultData = tableData;
  const [globalFilter, setGlobalFilter] = React.useState('')
  const columnHelper = createColumnHelper<RowObj>();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()
  const columns = [

    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          # Venta
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize='md' fontWeight='500'>
          {info.getValue()}
        </Text>
      )
    }),


    columnHelper.accessor('Titular', {
      id: 'Titular',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
Titular        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize='md' fontWeight='500'>
          {info.getValue()}
        </Text>
      )
    }),

    ,


    columnHelper.accessor('Acceso', {
      id: 'Acceso',
      header: () => (
        <Text
        display={'none'}
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
Acceso        </Text>
      ),
      cell: (info: any) => (
        <Text display={'none'} color={textColor} fontSize='md' fontWeight='500'>
          {info.getValue()}
        </Text>
      )
    }),
    
    columnHelper.accessor('Evento', {
      id: 'Evento',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Evento
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize='md' fontWeight='500'>
          {info.getValue()}
        </Text>
      )
    }),
    // columnHelper.accessor('email', {
    // 	id: 'email',
    // 	header: () => (
    // 		<Text
    // 			justifyContent='space-between'
    // 			align='center'
    // 			fontSize={{ sm: '10px', lg: '12px' }}
    // 			color='gray.400'>
    // 			EMAIL
    // 		</Text>
    // 	),
    // 	cell: (info) => ( 
    //     <Text color={textColor} fontSize='md' fontWeight='500'>
    //         {info.getValue()}
    //     </Text>
    // 	)
    // }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Fecha y Hora de Evento
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize='md' fontWeight='500'>
          {

            new Date(info.getValue()).toLocaleString('en-GB')
          }
        </Text>
      )
    }),

    columnHelper.accessor('Total', {
      id: 'Total',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Total
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize='md' fontWeight='500'>
          $ {info.getValue()}
        </Text>
      )
    }),   columnHelper.accessor('UsoAcceso', {
      id: 'UsoAcceso',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Estatus de Entrada
        </Text>
      ),
      cell: (info) => (
        <Badge
     
          colorScheme={info.getValue().toString() === 'true' ? 'green' : 'red'}

          fontSize='md'
          fontWeight='500'>
          {info.getValue() === true ? 'Usado' : 'Sin Entrar'
          }
        </Badge>
      )
    }),
    columnHelper.accessor('id', {
      id: 'actions',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Acciones
        </Text>
      ),
      
      cell: (info) => (
        <Text
          cursor='pointer'
          color={brandColor}
          textDecoration='underline'
          fontSize='md'
          fontWeight='500'
          id={info.getValue().toString()}

        >
          <Button onClick={() => {
            // setOrder(info.getValue().toString);
            setOrden(info.getValue())
            //setRazon(info.row.original.Provedor[0].RazonSocial);
            onOpen();

          }}>Detalle de Venta</Button>
        </Text>
      )
    })

  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 6,
    })

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )
  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })
  const createPages = (count: number) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  function onSubmit(values: any) {
    return new Promise(async (resolve) => {
      const formData = new FormData();
      for (var key in values) {
        let value = new Date(values[key])
        var followingDay = new Date(value.getTime() + 86400000); // + 1 day in ms
        let valuex = new Date(followingDay).toLocaleDateString('en-US', {
          day: 'numeric', month: 'numeric', year: 'numeric'
        })//.replace(/ /g, '/');
        formData.append(key, valuex);
      }
      alert(formData.get("FechaI"));
      alert(formData.get("FechaF"));
      ////http://127.0.0.1:3000/registros_candidatos?FechaI=01/01/2024&FechaF=05/28/2024

      window.location.replace(process.env.REACT_APP_URL_SERVER + '/registros_ventas?FechaI=' + formData.get("FechaI") + '&FechaF=' + formData.get("FechaF"))
      // const response = await fetch(process.env.REACT_APP_URL_SERVER + '/registros_candidatos?FechaI=' + formData.get("FechaI") + '&FechaF=' + formData.get("FechaF"),
      //   {
      //     method: "get"
      //   });
      // alert('Reporte Generado')
    })
  }

  React.useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'Anombre') {
      if (table.getState().sorting[0]?.id !== 'Anombre') {
        table.setSorting([{ id: 'Anombre', desc: false }])
      }
    }
  }, [table.getState().columnFilters[0]?.id])

  return (
    <Flex
      direction='column'
      w='100%'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex
        align={{ sm: "flex-start", lg: "flex-start" }}
        justify={{ sm: "flex-start", lg: "flex-start" }}
        w='100%'
        px='22px'
        mb='36px'>
        <DebouncedInput
          value={globalFilter ?? ''}
          onChange={value => setGlobalFilter(String(value))}
          className="p-2 font-lg shadow border border-block"
          placeholder="Busca..."
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex direction='row' mb={'30px'}>
            <FormLabel
              display='flex'
              ms='10px'
              htmlFor={'FechaIL'}
              fontSize='sm'
              color={textColorPrimary}
              fontWeight='bold'
              _hover={{ cursor: 'pointer' }}>
              {'Fecha Inicial'}
            </FormLabel>
            {/* <InputField
              type={'date'}
              id={'FechaI'}
              nombre="FechaI"
              required={true}
              fontWeight='500'
              variant='main'
              placeholder={'12/12/2023'}
              _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
              h='44px'
              maxH='44px'
              registro={register}
            /> */}
            <Input
              type={'date'}
              id={'FechaI'}
              required={true}

              fontWeight='500'
              variant='main'
              _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
              h='44px'
              maxH='44px'
              {...register('FechaI')}
            />
            <FormLabel
              display='flex'
              ms='10px'
              htmlFor={'FechaFL'}
              fontSize='sm'
              color={textColorPrimary}
              fontWeight='bold'
              _hover={{ cursor: 'pointer' }}>
              {'Fecha Final'}
            </FormLabel>
            <Input
              type={'date'}
              id={'FechaF'}
              required={true}

              fontWeight='500'
              variant='main'
              _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
              h='44px'
              maxH='44px'
              {...register('FechaF')}
            />
            {/* <InputField
              type={'date'}
              id={'FechaF'}
              nombre="FechaF"
              required={true}
              fontWeight='500'
              variant='main'
              placeholder={'12/12/2023'}
              _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
              h='44px'
              maxH='44px'
              //ref = {registro}
              registro={register}
            /> */}
            <Flex justify="center" ml="24px">
              <Button
                variant="darkBrand"
                type='submit'
                fontSize="sm"
                borderRadius="16px"
                w={{ base: '128px', md: '148px' }}
                h="46px"
              >
                Descargar Reporte
              </Button>
            </Flex>

          </Flex>
        </form>
      </Flex>
      <>
        <Modal isOpen={isOpen} onClose={onClose} size={'lg'} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader># Venta {orden} </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Invoice id={orden}></Invoice>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='red' mr={3} onClick={onClose}>
                Cerrar
              </Button>
              <Button colorScheme='green' mr={3} onClick={
                async () => {
                  let data = { 'id': orden }

                  const response = await fetch(process.env.REACT_APP_URL_SERVER+'/status_venta',
                    {
                      method: "POST",
                      mode: "cors",
                      cache: "no-cache",
                      credentials: "same-origin",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      redirect: "follow", // manual, *follow, error
                      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                      body: JSON.stringify(data), // body data type must match "Content-Type" header
                    });

                  alert('Asistencia Confirmada')
                }
              }>

                Confirmar Asistencia</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      <Table variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <Th
                    pe='10px'
                    borderColor={borderColor} key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <Flex
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    )}
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <Tr px="20px" key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <Td key={cell.id}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor={borderColor}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Flex w="100%" justify="space-between" px="20px" pt="10px" pb="5px">
        {/* SET ROW NUMBER */}
        <Text
          fontSize='sm'
          color='gray.500'
          fontWeight='normal'
          mb={{ sm: "24px", md: "0px" }}>
          Mostrando {pageSize * pageIndex + 1} de{" "}
          {pageSize * (pageIndex + 1) <= tableData.length
            ? pageSize * (pageIndex + 1)
            : tableData.length}{" "}
          de {tableData.length} Registros
        </Text>
        {/* PAGINATION BUTTONS */}
        <div className="flex items-center gap-2">
          <Stack direction='row' alignSelf='flex-end' spacing='4px' ms='auto'>
            <Button
              variant='no-effects'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              transition='all .5s ease'
              w='40px'
              h='40px'
              borderRadius='50%'
              bg='transparent'
              border='1px solid'
              borderColor={useColorModeValue("gray.200", "white")}
              display={
                pageSize === 5 ? "none" : table.getCanPreviousPage() ? "flex" : "none"
              }
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}>
              <Icon as={MdChevronLeft} w='16px' h='16px' color={textColor} />
            </Button>
            {createPages(table.getPageCount()).map((pageNumber, index) => {
              return (
                <Button
                  variant='no-effects'
                  transition='all .5s ease'
                  onClick={() => table.setPageIndex(pageNumber - 1)}
                  w='40px'
                  h='40px'
                  borderRadius='50%'
                  bg={
                    pageNumber === pageIndex + 1 ? brandColor : "transparent"
                  }
                  border={
                    pageNumber === pageIndex + 1
                      ? "none"
                      : "1px solid lightgray"
                  }
                  _hover={
                    pageNumber === pageIndex + 1
                      ? {
                        opacity: "0.7",
                      }
                      : {
                        bg: "whiteAlpha.100",
                      }
                  }
                  key={index}>
                  <Text
                    fontSize='sm'
                    color={pageNumber === pageIndex + 1 ? "#fff" : textColor}>
                    {pageNumber}
                  </Text>
                </Button>
              );
            })}
            <Button
              variant='no-effects'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              transition='all .5s ease'
              w='40px'
              h='40px'
              borderRadius='50%'
              bg='transparent'
              border='1px solid'
              borderColor={useColorModeValue("gray.200", "white")}
              display={pageSize === 5 ? "none" : table.getCanNextPage() ? "flex" : "none"}
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}>
              <Icon as={MdChevronRight} w='16px' h='16px' color={textColor} />
            </Button>
          </Stack>
        </div>

      </Flex>
    </Flex>
  )
}
// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])



  return (
    <SearchBar
      {...props}
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      h='44px'
      w={{ lg: "390px" }}
      borderRadius='16px'
    />
  )
}