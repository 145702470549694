import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import SignInDefault from 'views/auth/signIn/SignInDefault';
// import jwt from 'jsonwebtoken';
//import { Secret, decode, verify } from 'jsonwebtoken';

import AdminLayout from './layouts/admin';
import VentasLayout from './layouts/ventas';
// import RTLLayout from './layouts/rtl';
// import encuestas from './layouts/encuestas';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useEffect, useState } from 'react';
import { getCookie } from 'cookies-next';
import { useJwt } from 'react-jwt';
import jwt from 'jsonwebtoken';

// import AjustePositivo from 'views/admin/main/ERP/Configuracion/AjustePositivo';
import Encuestas from 'views/admin/main/ERP/Candidatos/Encuestas';
import { tokenToString } from 'typescript';
// import { redirect } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';
// Chakra imports
interface JwtPayload {
  usuarioid: number;
  nombre: string;
  tipo: string;
  // Add any other custom claims you might have
}
export default function Main() {
  // eslint-disable-next-line
  const [token, setToken] = useState<string | null>(null);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  // const jwt = useJwt(token);
  let usuarioid = '';
  const login_data = async () => {
    try {
      const token = getCookie('token');
      console.log(tokenToString);
      setToken(token || null);
    } catch (error) {
      console.error('Invalid token:', error);
    }
  };
  useEffect(() => {
    login_data();
  }, []);

  const { decodedToken, isExpired } = useJwt<JwtPayload>(token || '');

  return decodedToken ? (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        {decodedToken.tipo === 'admin' && (
          <Route
            path="admin/*"
            element={
              <AdminLayout
                theme={currentTheme}
                setTheme={setCurrentTheme}
                path="/admin/dashboards/default"
              />
            }
          />
        )}
         
        <Route
            path="ventas/*"
            element={
              <VentasLayout
                theme={currentTheme}
                setTheme={setCurrentTheme}
                path="/ventas"
              />
            }
          />
      </Routes>
    </ChakraProvider>
  ) : (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="/*" element={<SignInDefault />} />
      </Routes>
    </ChakraProvider>
  );
}
