// Chakra imports
import { Box, Button, Flex, Icon, Text, Image } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import React from 'react';
// Assets
import crepas from 'assets/img/profile/TTI.png';
import QRCode from 'react-qr-code';

import {
  MdAttachFile,
  MdCamera,
  MdCameraRoll,
  MdDownload,
  MdFileDownload,
  MdFileOpen,
  MdPrint,
  MdShare,
} from 'react-icons/md';
import Content from '../components/Content';
type ComponentToPrintProps = {
  bgButton: string;
  bgFocus: any;
  bgHover: any;
  [x: string]: any;
};

type RowObj = {
  Anombre: string;
  id: number;
  fechahora: string;
  updatedAt: string;
  Mesa: number;
  meserosId: number;
  Empresaid: number;
  PrecioTotal: string;
  createdAt: string;
  Estatus: boolean;
  actions: string;
  Provedor: string;
  Total: number;
};
export default class ComponentToPrint extends React.Component<
  ComponentToPrintProps,
  { value: RowObj[] }
> {
  // for react-to-print to work, it must be called from a class based component
  constructor(props: any) {
    super(props);

    this.state = { value: [] };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      let data = { id: 8 };
      // const url = process.env.REACT_APP_URL_SERVER+'/una_compra';
      console.error(this.props.id);
      const formData = new FormData();
      formData.append('id', this.props.id);
      const response = await fetch(
        process.env.REACT_APP_URL_SERVER + '/una_venta',
        {
          body: formData,
          method: 'post',
        },
      );

      // let responsex = await response.json()
      // console.log(responsex)
      const xD: RowObj[] = await response.json();
      this.setState({ value: xD });

      console.log(this.state.value);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  render() {
    //const { data } = this.state;
    //let res = this.state.value.map(option => option.Anombre);

    const { bgButton, bgFocus, bgHover, ...rest } = this.props;
    type RowObjx = {
      Anombre: string;
      id: number;
      fechahora: string;
      updatedAt: string;
      Mesa: number;
      meserosId: number;
      Empresaid: number;
      PrecioTotal: string;
      createdAt: string;
      Estatus: boolean;
      actions: string;
      Observacion: string;
    };

    var msgs = JSON.parse(JSON.stringify(this.state.value));
    console.log('==============>>>>>>>>>>>>>>>>>>>>>>>>>>');

    console.log(msgs);
    var Evidencia = msgs.Evidencia;
    var LineasProducto = msgs['Compra_Lineas'];
    console.log('==============>>>>>>>>>>>>>>>>>>>>>>>>>>');

    return (
      <Card {...rest} justifySelf="center" alignSelf="center">
        <Image
          style={{ width: '100%', height: '40%', alignSelf: 'center' }}
          src={crepas}
        />

        <QRCode
          size={256}
          style={{
            height: 'auto',
            maxWidth: '60%',
            width: '40%',
            alignSelf: 'center',
          }}
          value={String(msgs.Acceso)}
          viewBox={`0 0 256 256`}
        />

        <Flex alignSelf="center" my={{ base: 'auto', md: '0px' }}>
          <Button
            onClick={() => window.open(Evidencia)}
            ms="auto"
            me="10px"
            alignItems="center"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            p="7px"
            minW="unset"
            h="32px"
            lineHeight="100%"
            borderRadius="10px"
          >
            <Icon as={MdAttachFile} color="black" w="18px" h="18px" />
          </Button>
          {/* <Button
            onClick={() => window.open(PDF)}

            alignItems="center"
            me="10px"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            p="7px"
            minW="unset"
            h="32px"
            lineHeight="100%"
            borderRadius="10px"
          >
            <Icon as={MdCamera} color="black" w="18px" h="18px" />
          </Button>
          <Button
            onClick={() => window.open(XML)}
            alignItems="center"
            me="10px"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            p="7px"
            minW="unset"
            h="32px"
            lineHeight="100%"
            borderRadius="10px"
          >
            <Icon as={MdFileOpen} color="black" w="18px" h="18px" />
          </Button> */}
          <Button
            onClick={() => this.props.handlePrint()}
            ms="auto"
            me="10px"
            alignItems="center"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            p="7px"
            minW="unset"
            h="32px"
            lineHeight="100%"
            borderRadius="10px"
          >
            <Icon as={MdPrint} color="black" w="18px" h="18px" />
          </Button>
          <Button
              alignItems="center"
              me="10px"
              justifyContent="center"
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              p="7px"
              minW="unset"
              h="32px"
              lineHeight="100%"
              borderRadius="10px"
            >
              <Icon as={MdDownload} color="white" w="18px" h="18px" />
            </Button>
        </Flex>

        <Text
          alignSelf={'center'}
          fontSize="xl"
          fontWeight="bold"
          color="red.600"
          maxW="322px"
        >
          Titular: {msgs.Titular}
        </Text>

        <Text
          alignSelf={'center'}
          fontSize="xl"
          fontWeight="bold"
          color="red.600"
          maxW="322px"
        >
          Evento: {msgs.Evento}
        </Text>

        {/* <Text alignSelf={'center'} fontSize='small' fontWeight='bold' color='red.600' maxW='322px'>

         Monto Total: {Total}
        </Text> */}
        {LineasProducto && <Content  x={LineasProducto} y={0} />}
      </Card>
    );
  }
}
