import React from 'react';
import { NavLink } from 'react-router-dom';
// Chakra imports
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useJwt } from 'react-jwt';

// Custom components
import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/variants/Default';
// Assets
import illustration from 'assets/img/profile/TTW.png'; //CAMBIARLO POR TOGUI
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { getCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

function SignIn() {
  // Chakra color mode
  let genero = [
    { id: '1', valor: 'Administrador' },
    { id: '2', valor: 'Candidato' },
    { id: '3', valor: 'Comitiva' },
  ];
  const toast = useToast();

  async function login() {
    let data = { user: 'yahmedc@gmail.com', pwd: '2' };
    const response = await fetch('http://192.168.68.105:3000/login', {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    // const req = await fetch('http://192.168.68.105:3000/login',{
    // 	method : "POST",
    // 	redirect: "follow",
    // 	credentials : "include", // to send HTTP only cookies
    // 	headers: {
    // 	 "Contetnt-Type" : "application/json"
    //    },
    //    body : JSON.stringify(data)
    //   });
    //const result = await req.json();

    const resultx = await response.json();
    const responsex = await fetch('http://192.168.68.105:3000/content');
    console.error(await responsex.text());
  }

  interface JwtPayload {
    usuarioid: number;
    nombre: string;
    tipo: string;
    // Add any other custom claims you might have
  }
  const [token, setToken] = useState<string | null>(null);
  const { decodedToken, isExpired } = useJwt<JwtPayload>(token || '');

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
  const googleText = useColorModeValue('navy.700', 'white');
  const googleHover = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.300' },
  );
  const googleActive = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.200' },
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const x = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const login_data = async (token: any) => {
    try {
      //const token = getCookie('token');
      // console.log(tokenToStrin`g);
      setToken(token || null);
    } catch (error) {
      console.error('Invalid token:', error);
    }
  };

  const setDataAsync = (token:any) => {
    return new Promise<void>((resolve) => {
      setToken(token||null);
      resolve();
    });
  };


  useEffect(() => {
    if (decodedToken) {
    //   console.log('Token has been decoded', decodedToken);
	  console.log(decodedToken.tipo);
	    x('/'+decodedToken.tipo);
	    window.location.reload()
    } else {
      console.log('Decoded token is undefined');
    }
  }, [decodedToken]);

  function onSubmit(values: any) {
    return new Promise(async (resolve) => {
      let tipo = '';
      const formData = new FormData();
      for (var key in values) {
        //console.warn(key);
        let value = values[key];
        if (key == 'Tipo') tipo = value;
        formData.append(key, value);
      }
     // console.log(formData);

      const registrationToast = toast({
        title: 'Iniciando Sesion',
        status: 'loading',
      });

      var adicional = '';
      switch (parseInt(tipo)) {
        case 1:
          adicional = '/login_admin';
          break;
        case 2:
          adicional = '/login_canidato';
          break;
        case 3:
          adicional = '/login_personal';
          break;
      }

      const response = await fetch(
        process.env.REACT_APP_URL_SERVER + adicional,
        {
          body: formData,
          method: 'post',
        },
      );
      toast.close(registrationToast);
      toast({
        title: 'Fallo Inicio de Sesion...',
        status: 'error',
      });

      if (typeof response !== 'string') {
        const { token } = await response.json();
        if (token) {
          //await login_data(token);
          document.cookie = `token=${token}; path=/`;
        
		  
		 await setDataAsync(token);
		 // const { decodedToken, isExpired } = useJwt<JwtPayload>(token || '');

    
        } else {
          toast.close(registrationToast);
          toast({
            title: 'Error',
            description: 'Fallo el Login',
            status: 'error',
          });
        }
      } else {
        alert(response);
      }
    });
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          maxW={{ base: '100%', md: 'max-content' }}
          w="100%"
          mx={{ base: 'auto', lg: '0px' }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: '30px', md: '60px' }}
          px={{ base: '25px', md: '0px' }}
          mt={{ base: '40px', md: '14vh' }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              Inicia Sesion
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Ingresa tu Correo Electronico y Contraseña para acceder
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: '100%', md: '420px' }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            mb={{ base: '20px', md: 'auto' }}
          >
            <FormControl>
              <Flex direction="column" mb="34px">
                <FormLabel
                  ms="10px"
                  htmlFor="Tipo"
                  fontSize="sm"
                  color={textColor}
                  fontWeight="bold"
                  _hover={{ cursor: 'pointer' }}
                >
                  Tipo de Cuenta:
                </FormLabel>

                <Select
                  fontSize="sm"
                  id="Tipo"
                  variant="main"
                  h="44px"
                  maxH="44px"
                  name="Tipo"
                  // onChange={handleChangeEmpresa}
                  {...register('Tipo', { required: true })}
                >
                  <option> </option>
                  {genero.map((e, key) => {
                    return (
                      <option key={key} value={e.id}>
                        {e.valor}
                      </option>
                    );
                  })}
                </Select>
              </Flex>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="email"
                placeholder="root@tecno-innova.com.mx"
                mb="24px"
                fontWeight="500"
                size="lg"
                {...register('CorreoElectronico', { required: true })}
              />
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Contraseña <Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  mb="24px"
                  size="lg"
                  type={show ? 'text' : 'password'}
                  variant="auth"
                  {...register('Password', { required: true })}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent="space-between" align="center" mb="24px">
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    id="remember-login"
                    colorScheme="brandScheme"
                    me="10px"
                  />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="normal"
                    color={textColor}
                    fontSize="sm"
                  >
                    Mantener la sesion activa
                  </FormLabel>
                </FormControl>
                <NavLink to="/auth/forgot-password">
                  <Text
                    color={textColorBrand}
                    fontSize="sm"
                    w="124px"
                    fontWeight="500"
                  >
                    Olvidaste la contraseña?
                  </Text>
                </NavLink>
              </Flex>
              <Button
                fontSize="sm"
                type="submit"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
              >
                Iniciar Sesion
              </Button>
            </FormControl>
          </Flex>
        </Flex>
      </form>
    </DefaultAuth>
  );
}

export default SignIn;
